import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/Login.vue';
import PointsMall from '@/components/PointsMall.vue';
import orderSummary from '@/views/OrderSummary.vue';
import addAddress from '@/views/AddAddress.vue';
import orderSuccess from '@/views/OrderSuccess.vue';
import Profile from '@/views/Profile.vue';
import orderList from '@/views/OrderList.vue';
import addressList from '@/views/AddressList.vue';
import MyPoints from '@/views/MyPoints.vue';
import MyProfile from "@/views/MyProfile.vue";
import MyTasks from "@/views/MyTasks.vue";
import taskCenter from "@/views/taskCenter.vue";
const routes = [
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login },
    { path: '/pointsMall', name: 'PointsMall', component: PointsMall },
    { path: '/orderSummary', name: 'OrderSummary', component: orderSummary },
    { path: '/addAddress', name: 'AddAddress', component: addAddress },
    { path: '/orderSuccess', name: 'OrderSuccess', component: orderSuccess },
    { path: '/profile', name: 'Profile', component: Profile },
    { path: '/orderList', name: 'orderList', component: orderList },
    { path: '/addressList', name: 'addressList', component: addressList },
    { path: '/MyPoints', name: 'MyPoints', component: MyPoints },
    { path: '/MyProfile', name: 'MyProfile', component: MyProfile },
    { path: '/MyTasks', name: 'MyTasks', component: MyTasks },
    { path: '/taskCenter', name: 'taskCenter', component: taskCenter },
];
const router = createRouter({
    history: createWebHistory('/fenbi'),
    routes,
});
router.beforeEach(async (to, from, next) => {
    const token = localStorage.getItem('authToken'); // 从 localStorage 获取 token
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (token) {
            try {
                const { default: jwtDecode } = await import('jwt-decode');
                const decodedToken = jwtDecode(token);
                console.log(decodedToken);
                const currentTime = Date.now() / 1000; // 获取当前时间（秒）
                if (decodedToken.exp && decodedToken.exp < currentTime) {
                    // 如果 token 已过期，重定向到登录页面
                    localStorage.removeItem('authToken'); // 删除过期的 token
                    next('/login');
                }
                else {
                    next(); // token 有效，继续导航
                }
            }
            catch (error) {
                console.error('Invalid token:', error);
                next('/login'); // 如果 token 无效或解码失败，重定向到登录页面
            }
        }
        else {
            next('/login'); // 如果没有 token，重定向到登录页面
        }
    }
    else {
        next(); // 如果目标页面不需要认证，直接进入
    }
});
export default router;
