import apiClient from './http';
/**
 * 注册用户
 * @param data
 */
export const registers = (data) => {
    return apiClient.post('/fb/integral/user/register', data);
};
/**
 * 用户登录
 * @param data
 */
export const logins = (data) => {
    return apiClient.post('/sys/thirdLogin', data);
};
/**
 * 获取验证码
 * @param randomNumber
 */
export const captcha = (randomNumber) => {
    return apiClient.get(`/sys/captcha/randomImage/${randomNumber}`);
};
export const sendVerificationMail = (params, name) => {
    return apiClient.get(`/mail/sendVerificationMail?to=${params}&loginName=${name}`);
};
export const verifyCode = (email, verificationCode) => {
    return apiClient.get(`/mail/verifyCode?email=${email}&verificationCode=${verificationCode}`);
};
export const resetPassword = (data) => {
    return apiClient.post('/mail/resetPassword', data);
};
