import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import FooterNavigation from '@/components/FooterNavigation.vue';
import { ShoppingCart, Location, Coin, Notebook, User } from '@element-plus/icons-vue'; // 导入所需的图标
export default defineComponent({
    components: {
        FooterNavigation,
        ShoppingCart,
        Location,
        Coin,
        Notebook,
        User,
    },
    setup() {
        const router = useRouter();
        // 菜单配置
        const menuItems = [
            { label: '我的订单', route: '/orderList', icon: 'ShoppingCart' },
            { label: '我的地址', route: '/addressList', icon: 'Location' },
            { label: '我的积分', route: '/MyPoints', icon: 'Coin' },
            { label: '我的任务', route: '/MyTasks', icon: 'Notebook' },
            { label: '我的信息', route: '/MyProfile', icon: 'User' },
        ];
        const navigateTo = (route) => {
            router.push(route);
        };
        const logout = () => {
            localStorage.removeItem('authToken'); // 清除 token
            sessionStorage.removeItem('hasShownEmailAlert');
            router.push('/login'); // 跳转到登录页面
        };
        return {
            menuItems,
            navigateTo,
            logout,
        };
    },
});
