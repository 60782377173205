import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { taskList, taskGetAudit, taskAddAudit, taskUpload } from '@/api/mall';
import { Plus, Close } from '@element-plus/icons-vue';
import { imgUrl } from "@/config";
import { ElMessage } from 'element-plus';
export default defineComponent({
    components: {
        Plus,
        Close
    },
    setup() {
        const fileList = ref([]); // 当前文件列表
        const router = useRouter();
        const tasks = ref([]);
        const loading = ref(false);
        const resultDialogVisible = ref(false);
        const selectedTask = ref(null);
        const operationDialogVisible = ref(false); // 控制任务操作弹框
        const currentTask = ref(null); // 当前操作的任务
        const uploadImage = ref(null); // 上传的图片
        const description = ref(''); // 描述说明
        const details = ref([]);
        const openOperationDialog = async (type, task) => {
            fileList.value = [];
            currentTask.value = task;
            if (type === 'complete') {
                // 去完成：初始化为空
                uploadImage.value = null;
                description.value = '';
            }
            else {
                // 已上传或重新上传：从接口加载已有数据
                try {
                    description.value = '';
                    const response = await taskGetAudit(task);
                    console.log(response);
                    details.value = response.data.result.records;
                    // 提取数据
                    const data = response.data.result.records[0];
                    console.log(data);
                    const parsedImage = data.imageList === null ? [] : data.imageList; // 解析 JSON 数据
                    if (parsedImage.length != 0) {
                        fileList.value.push(`${parsedImage[0]}`);
                    }
                    else {
                        fileList.value = [];
                    }
                    // 设置其他字段
                    description.value = data?.description || '';
                }
                catch (error) {
                    console.error('加载任务详情失败:', error);
                }
            }
            operationDialogVisible.value = true; // 显示弹框
        };
        // 提交任务操作
        const submitTask = async () => {
            console.log(description.value);
            console.log(fileList.value.length);
            if (fileList.value.length === 0 && description.value === '') {
                ElMessage.error('请上传图片或描述说明');
                return;
            }
            try {
                const params = {
                    taskId: currentTask.value,
                    image: fileList.value,
                    description: description.value,
                    ...(details.value?.length > 0 ? { id: details.value[0].id } : {}),
                };
                const res = await taskAddAudit(params); // 提交接口
                if (res.data.code === 200) {
                    ElMessage.success('提交成功');
                    getTaskList();
                }
                else {
                    ElMessage.error(res.data.message);
                }
                operationDialogVisible.value = false; // 关闭弹框
            }
            catch (error) {
                ElMessage.error(error);
            }
        };
        // 自定义上传逻辑
        const customUpload = async (options) => {
            const { file, onSuccess, onError } = options; // 解构上传组件提供的参数
            const formData = new FormData(); // 创建 FormData 对象
            formData.append('file', file); // 将文件添加到 FormData 中
            formData.append('biz', 'temp'); // 添加额外参数
            try {
                const response = await taskUpload(formData); // 调用上传接口
                onSuccess(response); // 上传成功回调
                console.log('上传成功:', response);
            }
            catch (error) {
                console.error('上传失败:', error);
                onError(error); // 上传失败回调
            }
        };
        const close = () => {
            fileList.value = [];
        };
        const handleUploadSuccess = (response, file) => {
            fileList.value.push(`${imgUrl}${response.data.result}`);
        };
        // 上传失败回调
        const handleUploadError = (error) => {
            console.error('上传失败:', error);
        };
        // 上传图片预览
        const handlePreview = (file) => {
            window.open(file.url); // 打开预览图片
        };
        // 移除图片
        const handleRemove = (file) => {
            fileList.value = fileList.value.filter((item) => item.uid !== file.uid); // 从文件列表中移除
        };
        const goBack = () => {
            router.back();
        };
        const getTaskList = async () => {
            try {
                loading.value = true;
                const response = await taskList({});
                if (response && response.data && response.data.result && response.data.result.records) {
                    tasks.value = response.data.result.records; // 确保路径准确，类型断言
                }
                else {
                    ElMessage.error(response.data.message);
                }
            }
            catch (error) {
                ElMessage.error('获取任务列表失败:', error);
            }
            finally {
                loading.value = false;
            }
        };
        const isExpired = (endTime) => {
            if (endTime === null)
                return false;
            const now = Date.now();
            const endTimeStamp = new Date(endTime).getTime();
            return now > endTimeStamp;
        };
        const showCompleteButton = (task) => task.finishStatus === 0 && task.auditStatus === 0 && !isExpired(task.endTime);
        const showUploadedButton = (task) => task.finishStatus === 1 && (task.auditStatus === 0 || task.auditStatus === 3);
        const showReUploadButton = (task) => task.finishStatus === 0 && task.auditStatus === 3;
        const showViewResultButton = (task) => task.finishStatus === 1 && (task.auditStatus === 1 || task.auditStatus === 2);
        const openResultDialog = async (task = 0) => {
            const data = await taskGetAudit(task);
            selectedTask.value = data.data.result.records[0];
            resultDialogVisible.value = true;
        };
        // **加载任务数据**
        onMounted(() => {
            getTaskList();
        });
        const getAuditStatus = (status) => {
            switch (status) {
                case 0:
                    return '待审核';
                case 1:
                    return '审核通过';
                case 2:
                    return '审核不通过';
                case 3:
                    return '退回';
                default:
                    return ' ';
            }
        };
        return {
            tasks,
            loading,
            goBack,
            isExpired,
            showCompleteButton,
            showUploadedButton,
            showReUploadButton,
            showViewResultButton,
            openResultDialog,
            resultDialogVisible,
            selectedTask,
            getAuditStatus,
            openOperationDialog,
            operationDialogVisible,
            description,
            handlePreview,
            handleRemove,
            submitTask,
            customUpload,
            handleUploadSuccess,
            fileList,
            details,
            close
        };
    },
});
