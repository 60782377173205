<template>
  <router-view/>
</template>

<style lang="less">
html,body,p,h1,h2,h3,h4,ul,li{
  margin: 0;
  padding: 0;
}
</style>
